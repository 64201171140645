<template>
    <Subview hideShadow :contentPadding="false" :class="['main-content settings', $vuetify.breakpoint.xsOnly && 'pb-6']" customContentPadding="pa-0"
        backgroundColor="transparent" title="Booksy Med dla fizjoterapeutów">
        <v-container fluid :class="['mx-0 pa-0', $vuetify.breakpoint.xsOnly && 'pb-6']" slot="subview-content">
            <v-row class="px-3" wrap>
                <v-col :class="['pa-0 d-flex', $vuetify.breakpoint.xsOnly ? 'mb-2' : 'mr-2']"
                    style="flex-direction:column">
                    <SubviewCard class="flex-grow-1">
                        <v-layout column class="mx-1">
                            <DefaultSubtitle>Dlaczego warto?</DefaultSubtitle>
                            <DefaultLabel>{{
                                    `
                            Moduł Booksy Med, który za chwilę aktywujesz, to specjalistyczne narzędzie
                            stworzone dla fizjoterapeutów. Dzięki współpracy Booksy i Fizjorejestracji
                            dostaniesz nie tylko dostęp do najpopularniejszej platformy rezerwacyjnej
                            w Polsce i funkcji ułatwiających zarządzanie, ale także intuicyjny i szybki
                            system do prowadzenia dokumentacji medycznej w postaci elektronicznej.
                            `}}
                            </DefaultLabel>
                            <v-layout column class="my-3">
                                <ul>
                                    <li>
                                        <DefaultLabel>Integracja z platformą P1.</DefaultLabel>
                                    </li>
                                    <li>
                                        <DefaultLabel>Dostęp do klasyfikacji ICD-9, ICD-10 i ICF.</DefaultLabel>
                                    </li>
                                    <li>
                                        <DefaultLabel>3 rodzaje kart pacjenta: klasyczna, uroginekologiczna i
                                            pediatryczna.
                                        </DefaultLabel>
                                    </li>
                                    <li>
                                        <DefaultLabel>Zgodność z wymogami Ministerstwa Zdrowia i Krajowej Izby
                                            Fizjoterapeutów.
                                        </DefaultLabel>
                                    </li>
                                    <li>
                                        <DefaultLabel>Bezpieczeństwo danych dzięki tworzeniu kopii zapasowych.
                                        </DefaultLabel>
                                    </li>
                                </ul>
                            </v-layout>
                            <DefaultLabel>Wybierając Booksy Med, wybierasz profesjonalizm, wygodę, oszczędność czasu.
                                Zrób to teraz!</DefaultLabel>
                        </v-layout>
                    </SubviewCard>
                </v-col>
                <v-col :class="['pa-0 d-flex', $vuetify.breakpoint.xsOnly ? 'mt-2' : 'ml-2']"
                    style="flex-direction:column" :cols="$vuetify.breakpoint.xsOnly ? 12 : 5">
                    <SubviewCard class="flex-grow-1">
                        <v-layout column class="mx-1">
                            <DefaultSubtitle>Ile to kosztuje?</DefaultSubtitle>
                            <DefaultLabel class="my-1">Miesięczna opłata za Booksy Med to 50 PLN netto.</DefaultLabel>
                            <DefaultLabel class="my-3">Będzie doliczana do opłaty za subskrypcje od najbliższego okresu
                                rozliczeniowego.</DefaultLabel>
                            <v-layout justify-center class="my-3">
                                <div class="fizjo-icon"></div>
                            </v-layout>
                            <DefaultSubtitle class="mt-3">Zaznacz wymagane zgody, aby przejść dalej:</DefaultSubtitle>
                            <BooksyRulesDelegate v-model="checkbox1" @clicked="onClicked"></BooksyRulesDelegate>
                            <v-divider></v-divider>
                            <BooksyRulesDelegate v-model="checkbox2" @clicked="onClicked"></BooksyRulesDelegate>
                            <DefaultButton class="mt-6 mx-0" primary :loading="loading"
                                :disabled="!checkbox1 || !checkbox2" @click.prevent="enableMedicalAddon">
                                Aktywuj Booksy Med
                            </DefaultButton>
                        </v-layout>
                    </SubviewCard>
                </v-col>
            </v-row>
            <BooksyRulesPopup v-if="showRules" v-model="showRules"></BooksyRulesPopup>
        </v-container>
    </Subview>
</template>

<script>
export default {
    data() {
        return {
            checkbox1: false,
            checkbox2: false,
            showRules: false,
            loading: false
        }
    },
    components: {
        Subview: () => import("@/components/Subview"),
        SubviewCard: () => import("@/components/cards/SubviewCard"),
        DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
        DefaultLabel: () => import("@/components/text/DefaultLabel"),
        DefaultButton: () => import("@/components/buttons/DefaultButton"),
        BooksyRulesDelegate: () => import("@/components/delegates/BooksyRulesDelegate"),
        BooksyRulesPopup: () => import("@/components/popups/BooksyRulesPopup")
    },
    methods: {
        onClicked() {
            this.showRules = true;
        },
        enableMedicalAddon() {
            this.loading = true;
            this.$emit("register");
        },
    },
}
</script>

<style lang="scss" scoped>
.fizjo-icon {
    font-family: "b-icons";
    font-size: 50px;
    color: white;
    background-color: lightgray;
    border-radius: 50px;
    height: 80px;
    width: 80px;
    line-height: 80px;
    text-align: center;
}

li {
    color: black;
}
</style>